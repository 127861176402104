import React, { useRef, useState, useEffect } from 'react'
import { SearchOutlined, EyeOutlined, DeleteOutlined, PlusOutlined, QuestionCircleOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Input, Space, Table, Tooltip, Popconfirm, Tag, Checkbox, DatePicker, Select, Flex } from 'antd'
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { ActivityActionsTableColumns } from './Transactions.columns'
import { activityTransactionsExportCsvAll, fetchActivityTransactions, handleRejectThisActivityTransaction } from '../../../store/transactions/actions'
import { exportFormat, TRANSACTION_DATE_FORMAT, ACTIVITY_VALIDATION_METHOD_STATUS, transformUserIdFormat, RUNNING_TRANSACTION_TYPE, transformTransactionIdFormat } from './Transactions.const'
import { BE_SHORT_DATE_FORMAT, DEFAULT_PAGE_SIZE, PAGINATION, showTotalRecords } from '../../../const/utils'
import { ACTIVITY_VALIDATION_METHOD } from '../Activity/Activity.const'
import { CgArrowsV } from 'react-icons/cg'
import { fetchCommunityReportsCommunitiesList } from '../../../store/communityReport/action'
import FullscreenLoading from '../../../components/FullscreenLoading'

export const Transactions = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const transactions = useSelector(state => state.transactions.activitiesTransactions)
    // const goals = useSelector(state => state.goals.goals)
    const communities = useSelector(state => state.communityReport?.communities)

    const [communitiesData, setCommunitiesData] = useState([])

    const searchInput = useRef(null)

    const [prepairActivityActionsTableColumns, setPrepairActivityActionsTableColumns] = useState(null)

    const [currentPagination, setCurrentPagination] = useState(PAGINATION)

    const [isShowPagination, setIsShowPagination] = useState(true)

    const [selectedCommunity, setSelectedCommunity] = useState(null)

    const [defaultCommunity, setDefaultCommunity] = useState(null)

    const [isReadyToGetDefaultData, setIsReadyToGetDefaultData] = useState(false)

    const [userRole, setUserRole] = useState('')
    const networkLoading = useSelector(state => state.common.networkLoading)

    const myCorporateProfile = useSelector(state => state?.community?.myCorporateProfile)

    const handleColumnSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
    }

    const handleReset = (clearFilters) => {
        clearFilters()
    }

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block'
                    }}
                />
                <Space>
                    <Button
                        className='mx-2 bg-[#207868]'
                        type='primary'
                        onClick={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined
                }}
            />
        ),
        onFilter: (value, record) => {
            if (record[dataIndex]) {
                if (dataIndex === 'status') {
                    return record[dataIndex]?.props.children.toLowerCase().includes(value.toLowerCase())
                } else {
                    return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                }
            } else {
                return false
            }

        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100)
            }
        }
    })

    useEffect(() => {
        // dispatch(fetchAllGoals())
        const userData = JSON.parse(sessionStorage.getItem("userData"))
        const { role } = userData?.user
        setUserRole(role)
        dispatch(fetchCommunityReportsCommunitiesList())
        dispatch(fetchActivityTransactions())
        const newActivityActionsTableColumns = ActivityActionsTableColumns.map((item) => {
            if (item.notAllowToSearch === true) {
                return item
            } else {
                return {
                    ...item,
                    ...getColumnSearchProps(item['dataIndex'])
                }
            }
        })
        setPrepairActivityActionsTableColumns(newActivityActionsTableColumns)
    }, [])

    useEffect(() => {
        if (userRole && communities) {
            if (userRole === 'community_leader') {
                const currentCommunityData = communities.filter((item) => { return item?.communityId === myCorporateProfile?._id })
                setCommunitiesData(currentCommunityData)
                if (currentCommunityData.length > 0) {
                    setDefaultCommunity({ label: currentCommunityData[0]?.communityName, value: currentCommunityData[0]?.communityId })
                } else {
                    setDefaultCommunity({ label: myCorporateProfile?.name, value: myCorporateProfile?._id })
                }
            } else {
                setCommunitiesData(communities)
                setDefaultCommunity(null)
            }
        }
    }, [communities, userRole])
    //This will prevent API call 3 times when page is loaded first time
    // }, [userRole])

    useEffect(() => {
        if (defaultCommunity) {
            setSelectedCommunity(defaultCommunity?.value)
            setIsReadyToGetDefaultData(true)
        } else {
            setSelectedCommunity(null)
            setIsReadyToGetDefaultData(true)
        }
    }, [defaultCommunity])


    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [filterDateRange, setFilterDateRange] = useState(null)
    const [filterStatus, setFilterStatus] = useState(null)
    const [filterValidationMethodParam, setFilterValidationMethodParam] = useState([])
    const filterStatuses = Object.values(ACTIVITY_VALIDATION_METHOD_STATUS).filter(i => i.value !== 'PENDING')
    const filterValidationMethod = Object.values(ACTIVITY_VALIDATION_METHOD).filter(item => item.value !== ACTIVITY_VALIDATION_METHOD.SHARE_WITH_COMMUNITY.value)
    // const [selectedRows, setSelectedRows] = useState([])
    const [wordEntered, setWordEntered] = useState()

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys)
        // console.log(newSelectedRowKeys)
        // setSelectedRows(newSelectedRowKeys.map(i => transactions?.result[i]))
    }

    const rowSelection = {
        ...selectedRowKeys,
        // ...selectedRows,
        onChange: onSelectChange
    }

    const handleSearch = (event) => {
        const searchWord = event.target.value
        setWordEntered(searchWord)
    }

    const handleStatusFilter = (value) => {
        // console.log(value)
        setFilterStatus(value)
        // dispatch(fetchActivityTransactions({ status: value }))
    }

    /**
     * Export all CSV
     */
    const _handleActivityTransactionsExportCsvAll = () => {
        const params = {

        }
        if (!!wordEntered) {
            params['search'] = wordEntered.toLowerCase()
        }
        if (filterDateRange) {
            params['fromDate'] = moment(new Date(filterDateRange[0])).format(`DD/MM/YYYY`)
            params['toDate'] = moment(new Date(filterDateRange[1])).format(`DD/MM/YYYY`)
        }
        if (filterStatus) {
            params['status'] = filterStatus
        }
        if (filterValidationMethodParam?.length > 0) {
            params['validationMethod'] = filterValidationMethodParam.join(',')
        }
        if (selectedCommunity) {
            params['communityId'] = selectedCommunity
        }
        dispatch(activityTransactionsExportCsvAll(params))
    }

    /**
     * Export selected records
     */
    const _handleActivityTransactionsExportCsvSelected = () => {
        if (selectedRowKeys.length > 0) {
            dispatch(activityTransactionsExportCsvAll({}, selectedRowKeys))
        } else {
            // const params = {}
            // if (!!wordEntered) {
            //   params['search'] = wordEntered.toLowerCase()
            // }
            // if (filterDateRange) {
            //   params['fromDate'] = moment(filterDateRange[0]).format(`DD/MM/YYYY`)
            //   params['toDate'] = moment(filterDateRange[1]).format(`DD/MM/YYYY`)
            // }
            // if (filterStatus) {
            //   params['status'] = filterStatus
            // }
            // if (filterValidationMethodParam?.length > 0) {
            //   params['validationMethod'] = filterValidationMethodParam.join(',')
            // }
            // //export from current result
            // // if (transactions?.result.length > 0) {
            // // const filterIds = transactions?.result.map(e => e._id)
            // dispatch(activityTransactionsExportCsvAll(params))
            // // }
        }
    }

    const _transformValidationMethod = (_method) => {
        try {
            return Object.values(ACTIVITY_VALIDATION_METHOD).filter(i => i.value == _method)[0].label
        } catch (e) {
        }
        return _method
    }

    const _handleSearchAndFilter = (pagination = PAGINATION) => {
        const params = {
            ...pagination
        }
        const _current = JSON.parse(JSON.stringify(currentPagination))
        _current.current = pagination?.current || PAGINATION.current
        _current.pageSize = pagination?.pageSize || PAGINATION.pageSize
        setCurrentPagination(_current)
        if (!!wordEntered) {
            params['search'] = wordEntered.toLowerCase()
        }
        if (filterDateRange) {
            params['fromDate'] = moment(new Date(filterDateRange[0])).format(`DD/MM/YYYY`)
            params['toDate'] = moment(new Date(filterDateRange[1])).format(`DD/MM/YYYY`)
        }
        if (filterStatus) {
            params['status'] = filterStatus
        }
        if (filterValidationMethodParam?.length > 0) {
            params['validationMethod'] = filterValidationMethodParam.join(',')
        }
        if (selectedCommunity) {
            params['communityId'] = selectedCommunity
        }

        dispatch(fetchActivityTransactions(params))
    }

    useEffect(() => {
        if (isReadyToGetDefaultData) {
            _handleSearchAndFilter()
            setIsReadyToGetDefaultData(false)
        }
    }, [isReadyToGetDefaultData])

    const handleTableChange = (pagination, filters, sorter) => {
        // dispatch(fetchActivityTransactions(pagination))
        const isNullUndefEmptyStr = Object.values(filters).every(value => {
            // 👇️ check for multiple conditions
            if (value === null || value === undefined || value === '') {
                return true
            }
            return false
        })
        if (isNullUndefEmptyStr === false) {
            pagination = {
                ...pagination,
                current: currentPagination.current
            }
            setIsShowPagination(false)
        } else {
            if (isShowPagination === false) {
                pagination = {
                    ...pagination,
                    current: currentPagination.current
                }
                setIsShowPagination(true)
            }
        }
        _handleSearchAndFilter(pagination)
    }


    const _handleFromToSelected = (v) => {
        console.log(`_handleFromToSelected `, v)
        setFilterDateRange(v)
    }

    const _handleValidationMethodFilter = (_val) => {
        setFilterValidationMethodParam(_val)
    }

    const _successCallbackRejectThisTransaction = (e) => {
        _handleSearchAndFilter()
    }

    /**
     * #45721 - Reward Transaction - add option to allow the admin reject the transaction
     * @param {*} _id 
     */
    const _handleRejectThisTransaction = (_id) => {
        dispatch(handleRejectThisActivityTransaction([_id], _successCallbackRejectThisTransaction))
    }

    /**
     * #45721 - Reward Transaction - add option to allow the admin reject the transaction
     * @param {*} item 
     * @returns 
     */
    const _rewardAction = (item) => {
        if (item?.status === 'COMPLETED') {
            return (
                <div style={{ minWidth: '65px' }}>
                    <Button style={{ marginRight: "8px" }} onClick={() => { navigate((`/transactions/info/${item?._id}`)) }} size="small" type="text" icon={<EyeOutlined />}>Detail</Button>
                    <Popconfirm
                        title="Are you sure to reject this transaction?"
                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        onConfirm={() => _handleRejectThisTransaction(item._id)}
                        onCancel={() => null}
                        okText="Yes"
                        okType="danger"
                        placement="topLeft"
                        cancelText="Cancel"
                    >
                        <Tooltip title="Reject this transaction">
                            <Button size="small" type="text" danger icon={<DeleteOutlined danger />}>Reject</Button>
                        </Tooltip>
                    </Popconfirm>
                </div>
            )
        }
        return <div style={{ minWidth: '65px' }}>
            <Button style={{ marginRight: "8px" }} onClick={() => { navigate((`/transactions/info/${item?._id}`)) }} size="small" type="text" icon={<EyeOutlined />}>Detail</Button></div>
    }

    const hasSelected = selectedRowKeys.length > 0// || (transactions && transactions?.result?.length > 0)

    const _handleRejectTransactionsSelected = () => {
        if (selectedRowKeys.length > 0) {
            dispatch(handleRejectThisActivityTransaction(selectedRowKeys, _successCallbackRejectThisTransaction))
        } else {

        }
    }
    return (
        <div className='bg-white shadow'>
            <PageHeader
                ghost={false}
                title="Activity Transaction"
            ></PageHeader>
            <Flex gap="middle">
                <Flex gap="middle" vertical>
                    <Flex gap="middle">
                        <Input
                            className='w-[450px] h-[40px] ml-4'
                            placeholder='Search Transaction Id, Email, Activity Name'
                            value={wordEntered}
                            onChange={handleSearch}
                            prefix={<SearchOutlined />}
                        />
                        <DatePicker.RangePicker onChange={(v) => _handleFromToSelected(v)} />
                    </Flex>
                    <Flex gap="middle" className='mb-4'>
                        <Select
                            showSearch
                            allowClear
                            // bordered={false}
                            value={selectedCommunity}
                            className='w-[300px] h-[40px] ml-4'
                            placeholder="Select community"
                            optionFilterProp='label'
                            options={communitiesData?.map(i => ({ label: i.communityName, value: i.communityId }))}
                            suffixIcon={<CgArrowsV />}
                            disabled={userRole != 'admin'}
                            onChange={(h) => { setSelectedCommunity(h) }}
                        />
                        <Select
                            // bordered={false}
                            className='w-[200px] h-[40px]'
                            placeholder='Status'
                            name='status'
                            allowClear
                            onChange={handleStatusFilter}
                            options={filterStatuses.map(i => ({ label: i.label, value: i.value }))}
                        />

                        <Select
                            name='validationMethod'
                            mode="multiple"
                            // bordered={false}
                            className='w-[200px] h-[40px]'
                            placeholder='Validation type'
                            // optionFilterProp='label'
                            options={filterValidationMethod.map(c => ({ label: c.label, value: c.value }))}
                            onChange={_handleValidationMethodFilter}
                            allowClear
                        />
                    </Flex>
                </Flex>
                <Flex gap="middle" vertical>
                    <Flex gap="middle">
                        <Button className='w-[200px] h-[40px]' onClick={_handleSearchAndFilter}>Apply Search & Filter</Button>
                        <Button className='w-[200px] h-[40px] bg-[#207868]' type='primary' disabled={!(transactions?.result)} onClick={_handleActivityTransactionsExportCsvAll}>
                            {`Export by Current Filter`}
                        </Button>
                    </Flex>
                    <Flex gap="middle">
                        <Button className='w-[200px] h-[40px] border-[#207868] text-[#207868]' disabled={!hasSelected} onClick={_handleActivityTransactionsExportCsvSelected}>
                            {`Export Selected`}
                        </Button>
                        <Popconfirm
                            title="Are you sure to reject selected transactions?"
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            onConfirm={_handleRejectTransactionsSelected}
                            onCancel={() => null}
                            okText="Yes"
                            okType="danger"
                            placement="topLeft"
                            cancelText="Cancel"
                        >
                            <Button danger className='w-[200px] h-[40px] text-[#207868]' disabled={!hasSelected}>
                                {`Reject Selected`}
                            </Button>
                        </Popconfirm>
                    </Flex>
                </Flex>
            </Flex>
            <Table
                className='z-[0] relative overflow-auto'
                rowSelection={rowSelection}
                columns={prepairActivityActionsTableColumns}
                dataSource={transactions?.result?.map((i, it) => ({
                    key: i?._id,
                    srno: (++it + ((currentPagination?.current - 1) * currentPagination?.pageSize)),
                    transactionId: transformTransactionIdFormat(i, RUNNING_TRANSACTION_TYPE.TRANSACTION.value),
                    submittedData: i?.createdAt && (moment(i?.createdAt).format(TRANSACTION_DATE_FORMAT)),
                    userId: transformUserIdFormat(i?.userProfile),
                    name: i?.userProfile?.name,
                    email: i?.userProfile?.email,
                    activity: i?.activityData?.name,
                    // goal
                    transactionMethod: _transformValidationMethod(i?.activityData?.validationMethod),
                    // habits: i?.habitsData?.map(item => item.name).join(", "),
                    points: i?.points,
                    remarks: i?.remarks,
                    // cancelReason
                    status: i?.status ? <Tag color={i?.status == 'COMPLETED' ? 'green' : 'red'}>{i?.status}</Tag> : '',
                    action: _rewardAction(i)
                }))}
                pagination={{
                    showTotal: showTotalRecords,
                    defaultPageSize: DEFAULT_PAGE_SIZE,
                    showSizeChanger: true,
                    // hideOnSinglePage: true,
                    position: ["none", isShowPagination === true ? "bottomRight" : "none"],
                    total: transactions?.totalCount,
                    current: currentPagination?.current
                }}
                onChange={handleTableChange}
            />
            {networkLoading && <FullscreenLoading></FullscreenLoading>}
        </div>
    )
}
