import React, { useEffect, useState } from 'react'
import { Button, Layout, Menu, Dropdown, Drawer, PageHeader, Tooltip, Tag, Spin, Icon } from 'antd'
import {
    SettingOutlined,
    LogoutOutlined,
    MenuOutlined,
    UserOutlined,
    LoadingOutlined
} from '@ant-design/icons'
import { useNavigate } from 'react-router'
import AdminSidePanelMenu from './components/AdminSidePanelMenu'
import { useDispatch, useSelector } from 'react-redux'

import AdminLogoSmall from '../assets/images/logo-xm.png'
import AdminLogoLarge from '../assets/images/logo-sm.png'

import packageJson from '../../package.json'
import { SUSGAIN_ROLES } from '../const/roles'
import { canIAccessThisWithMyRoles } from '../const/utils'
import { fetchMyCorporateProfile } from '../store/community/actions'
import FullscreenLoading from '../components/FullscreenLoading'

const { Header, Content, Footer, Sider } = Layout
const { SubMenu } = Menu

const ProfileMenu = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {

    }, [])

    const doLogout = async () => {
        await sessionStorage.removeItem("userData")
        navigate('/login')
    }

    const handleMenuClick = (e) => {
        if (e.key === '2') {
            doLogout()
        }
    }

    const navigateToChangePassword = (() => navigate('/change-password'))
    // const logoutUser = (() => localStorage.removeItem("userData"))
    // const logoutUser = (() => sessionStorage.removeItem("userData"))

    const menu__items = [
        {
            label: 'Change Password',
            key: '1',
            icon: <UserOutlined />,
            onClick: navigateToChangePassword
        },
        {
            label: 'Log out',
            key: '2',
            icon: <LogoutOutlined />,
            onClick: doLogout
        }
    ]

    const menu = (
        <Menu
            onClick={(e) => handleMenuClick(e)}
            items={menu__items}
        />
    )

    const _welcomeUser = () => {
        try {
            const { user } = JSON.parse(sessionStorage.getItem("userData"))
            return <>{user.name}</>
        } catch (e) {
            return <></>
        }
    }

    const _transformRoleName = (role) => {
        try {
            return Object.values(SUSGAIN_ROLES).find(i => i.role == role).label
        } catch (e) {
            return role
        }
    }

    const _welcomeUserRole = () => {
        try {
            const { user } = JSON.parse(sessionStorage.getItem("userData"))
            return <>{_transformRoleName(user.role)}</>
        } catch (e) {
            return <></>
        }
    }
    return (
        <div style={{ position: 'absolute', top: 0, right: 10 }}>
            <Dropdown className="drop-shadow" overlay={menu} placement="bottomRight" arrow>
                <div className="drop-shadow">
                    <Button shape="circle" type='default' coloe="primary" icon={<UserOutlined />} />
                </div>
            </Dropdown>
            {/* <Menu
                theme="light"
                mode="horizontal"
                defaultSelectedKeys={['m']}
                style={{ position: 'absolute', top: 0, right: 0 }}
            >
                <Menu.Item
                    icon={<UserOutlined />}
                    onClick={navigateToChangePassword}>Change Password
                </Menu.Item>
                <Menu.Item
                    icon={<LogoutOutlined />} onClick={doLogout}>Log out
                </Menu.Item>
            </Menu> */}
        </div>
    )
    // return (
    //     <div className='flex'>
    //         <Dropdown className="drop-shadow" overlay={menu} placement="bottomRight" arrow>
    //             <div className="drop-shadow">
    //                 <Button shape="circle" type="dashed" coloe="primary" icon={<UserOutlined />} />
    //             </div>
    //         </Dropdown>
    //         <div className='ml-2 grid'>
    //             <p className={`text-gray-600 mb-0 font-semibold`}>{_welcomeUser()}</p>
    //             <p style={{ lineHeight: '10px' }} className={`text-gray-400 mb-0`}>{_welcomeUserRole()}</p>
    //         </div>
    //     </div>
    // )
}

const AdminLayout = ({ children }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const _welcomeUser = () => {
        try {
            const { user } = JSON.parse(sessionStorage.getItem("userData"))
            return <>{user.name}</>
        } catch (e) {
            return <></>
        }
    }

    const _transformRoleName = (role) => {
        try {
            return Object.values(SUSGAIN_ROLES).find(i => i.role == role).label
        } catch (e) {
            return role
        }
    }

    const _welcomeUserRole = () => {
        try {
            const { user } = JSON.parse(sessionStorage.getItem("userData"))
            return <>{_transformRoleName(user.role)}</>
        } catch (e) {
            return <></>
        }
    }

    const myCorporateProfile = useSelector(state => state.community.myCorporateProfile)

    useEffect(() => {
        if (canIAccessThisWithMyRoles([SUSGAIN_ROLES.COMMUNITY_LEADER.role])) {
            dispatch(fetchMyCorporateProfile())
        }
    }, [])

    const [collapsed, setCollapsed] = useState(false)

    // const collapsed = false //useSelector(state => state.common.collapsed)
    const blockUi = useSelector(state => state.common.blockUi)
    const networkLoading = useSelector(state => state.common.networkLoading)


    const onCollapse = collapsed => {
        setCollapsed(collapsed)
    }
    const [drawer, setDrawer] = useState(false)

    const _renderLogo = () => {
        if (canIAccessThisWithMyRoles([SUSGAIN_ROLES.COMMUNITY_LEADER.role])) {
            if (!!myCorporateProfile && !!myCorporateProfile.logo) {
                return (
                    <a href='/corporate-dashboard'><img src={myCorporateProfile?.logo || AdminLogoLarge} className="img-fluid circle-profile" /></a>
                )
            }
        } else {
            if (collapsed) {
                return (<a href='/dashboard'><img src={AdminLogoSmall} className="img-fluid" /></a>)
            }
            return (
                <a href='/dashboard'><img src={AdminLogoLarge} className="img-fluid" /></a>
            )
        }
    }

    const _renderFooter = () => {
        return (
            <p className='text-gray-400 mb-0 text-center'>{`Ⓒ susGain 2024`}</p>
        )
    }

    const _renderSider = () => {
        return (<>
            <div className="p-4">
                {_renderLogo()}
            </div>

            <div className='text-center mb-10'>
                <p className={`text-gray-600 mb-0 font-semibold`}>Welcome {_welcomeUser()}!</p>
                <p style={{ lineHeight: '10px' }} className={`text-gray-400 mb-0`}>{_welcomeUserRole()}</p>
            </div>

            <AdminSidePanelMenu />
            <div className="p-4">
                <p className='text-gray-400 mb-0 text-center'>v{packageJson.version}-{process.env.REACT_APP_SENTRY_ENV}</p>
            </div>
        </>)
    }

    const _renderHeader = () => {
        return (
            <>
                {process.env.REACT_APP_SENTRY_ENV !== 'prod' ? <Tag color="orange">STAG Dashboard</Tag> : <></>}
                {ProfileMenu()}
                {/* <Menu
                    theme="light"
                    mode="horizontal"
                    defaultSelectedKeys={['11']}
                    style={{ lineHeight: '64px' }}
                >
                    <Menu.Group align="right">
                        <Menu.Item key="20">Account</Menu.Item>
                        <Menu.Item key="21">Logout</Menu.Item>
                    </Menu.Group>
                </Menu> */}
            </>
        )
    }

    const _renderContent = (children) => {
        return (
            <div className="p-2 overflow-auto">
                {children}
            </div>
        )
    }
    return (
        <>
            <Layout theme="light">
                <Sider collapsible theme="light" onCollapse={onCollapse}>{_renderSider()}</Sider>
                <Layout theme="light">
                    <Header theme="light" className={`bg-white`}>{_renderHeader()}</Header>
                    <Content theme="light">{_renderContent(children)}</Content>
                    <Footer theme="light" style={{ textAlign: 'center' }}>{_renderFooter()}</Footer>
                    {networkLoading ? <FullscreenLoading></FullscreenLoading> : ''}
                </Layout>
            </Layout>
        </>
    )
}

export default AdminLayout
