import React from 'react'
import { Button, PageHeader, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

function FullscreenLoading() {
  return (
    <Spin className='networkLoading' indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} tip="Loading" size="large" />
  )
}

export default FullscreenLoading
