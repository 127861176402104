import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Modal, Steps, Typography,  Button, Spin, message, Image, Checkbox } from "antd"
import { InputOTP } from 'antd-input-otp'

import ServerApi from "../../../utility/ServerApi"

const { Step } = Steps
const { Text } = Typography

const Setup2FA = ({ visible, onClose, onSuccess }) => {
  const dispatch = useDispatch()
  const [currentStep, setCurrentStep] = useState(0) // Step tracker
  const [loading, setLoading] = useState(false)
  const [qrCodeUrl, setQrCodeUrl] = useState("")
  const [secret, setSecret] = useState("")
  const [value, setValue] = useState([])
  const [checked, setChecked] = useState(false)

  const { verifyData } = useSelector((state) => ({
    verifyData: state.auth.verifyData
  }))

  const next = () => setCurrentStep(currentStep + 1)
  const back = () => setCurrentStep(currentStep - 1)

  const handleVerify = async () => {
    setLoading(true)
    await ServerApi().post(`auth/2fa/verify`, { email: verifyData, code: value.join(""), isBrowserRemembered: checked })
    .then(res => {
      sessionStorage.setItem("userData", JSON.stringify(res.data?.data))
      dispatch({ type: "SET_2FA_DATA", payload: null })
      onSuccess()
    }).catch(e => {
        console.error(e)
        message.error(e?.response?.data?.message || "Invalid OTP")
    }).finally(() => {
        setLoading(false)
    })
  }

  // Render the modal content based on the step
  const steps = [
    {
      title: "Setup 2FA",
      content: (
        <div className="flex flex-col gap-2">
          {loading ? (
            <Spin />
          ) : (
            <>
              <p className="text-center">
                <Image preview={false} src={qrCodeUrl} alt="QR Code for 2FA" className="text-center" />
                <p>Scan this QR code with your authenticator app then continue to verify.</p>
                <Text copyable={{ text: secret }} className="font-medium">{secret}</Text>
              </p>
              <Button onClick={next} className="w-fit mx-auto">
                Continue to Verify
              </Button>
            </>
          )}
        </div>
      )
    },
    {
      title: "Verify 2FA",
      content: (
        <div className="flex flex-col items-center gap-4">
          <InputOTP onChange={setValue} value={value} classNames={{
            input: 'w-10 h-10'
          }} />
          <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)}>Remember this browser for 30days</Checkbox>
          <div className="flex items-center justify-center gap-2">
            <Button onClick={() => {
                back()
                setValue([])
            }} type="dashed">
                Back
            </Button>
            <Button loading={loading} onClick={handleVerify}>
                Verify OTP
            </Button>
          </div>
        </div>
      )
    }
  ]

  useEffect(() => {
    let isMounted = true
    const generateQR = async () => {
        setLoading(true)
        await ServerApi().post(`auth/2fa/generate`, { email: verifyData })
        .then(res => {
          if (isMounted) {
            setQrCodeUrl(res.data?.data?.qrCodeUrl)
            setSecret(res.data?.data?.secret)
          }
        }).catch(e => {
            console.error(e)
            message.error(e?.response?.data?.message || "Invalid Email / Password")
        }).finally(() => {
            setLoading(false)
        })
    }
    if (verifyData) {
        generateQR()
    }

    return () => {
        isMounted = false
    }

  }, [verifyData])

  return (
    <Modal title="Two-Factor Authentication" open={visible} onCancel={() => {
        onClose()
        setCurrentStep(0)
        setValue([])
        dispatch({ type: "SET_2FA_DATA", payload: null })
    }} footer={null} destroyOnClose width={650}>
      <Steps current={currentStep} size='small'>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content" style={{ marginTop: "20px" }}>
        {steps[currentStep].content}
      </div>
    </Modal>
  )
}

export default Setup2FA
