import { FETCH_ACTIVITY_TRANSACTION, FETCH_PENDING_ACTIVITY_TRANSACTION, FETCH_SHARE_WITH_COMMUNITY_ACTIVITY_TRANSACTION, FETCH_SHARE_WITH_COMMUNITY_TRANSACTION_BY_ID, FETCH_TRANSACTION_BY_ID } from "./types"
import { toggleNetworkLoading } from "../common/actions"
import { message } from 'antd'
import ServerApi from "../../utility/ServerApi"
import { PAGINATION } from "../../const/utils"
import ServerApiCSV from "../../utility/ServerApiCSV"
import moment from 'moment'

export const fetchActivityTransactions = (filters = PAGINATION, callback = null) => dispatch => {
    // console.log(`fetchActivityTransactions filters `, filters)
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/activities-instances/activity-transactions?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.search ? `&search=${encodeURIComponent(filters?.search).replace(/%20/, "+")}` : ''}${filters?.status ? `&status=${filters?.status}` : ''}${filters?.validationMethod ? `&validationMethod=${filters?.validationMethod}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}`)
        .then(res => {
            dispatch({
                type: FETCH_ACTIVITY_TRANSACTION,
                payload: res.data
            })
            if (callback) callback(res.data)
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in Fetching Transactions", e)
            console.log(e)
        })
}

/**
 * #46334 - Add new PENDING Transactions
 * @param {*} filters 
 * @param {*} callback 
 * @returns 
 */
export const fetchPendingActivityTransactions = (filters = PAGINATION, callback = null) => dispatch => {
    // console.log(`fetchActivityTransactions filters `, filters)
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/activities-instances/pending-activity-transactions?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.search ? `&search=${encodeURIComponent(filters?.search).replace(/%20/, "+")}` : ''}${filters?.status ? `&status=${filters?.status}` : ''}${filters?.validationMethod ? `&validationMethod=${filters?.validationMethod}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}`)
        .then(res => {
            dispatch({
                type: FETCH_PENDING_ACTIVITY_TRANSACTION,
                payload: res.data
            })
            if (callback) callback(res.data)
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in Fetching Transactions", e)
            console.log(e)
        })
}
export const fetchTransactionById = (id) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/activities-instances/activity-transactions-details/${id}`)
        .then(res => {
            dispatch({
                type: FETCH_TRANSACTION_BY_ID,
                payload: res.data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            message.error("Error Fetching data!")
            dispatch(toggleNetworkLoading(false))
            console.log(e)
        })
}

/**
 * #45852 - Dashboard UX: Share with Community approval process
 * @param {*} id 
 * @returns 
 */
export const fetchShareWithCommunityTransactionById = (id) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/activities-instances/share-with-community-activity-transactions-details/${id}`)
        .then(res => {
            dispatch({
                type: FETCH_SHARE_WITH_COMMUNITY_TRANSACTION_BY_ID,
                payload: res.data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            message.error("Error Fetching data!")
            dispatch(toggleNetworkLoading(false))
            console.log(e)
        })
}


/**
 * #45852 - Dashboard UX: Share with Community approval process
 * @param {*} filters 
 * @param {*} callback 
 * @returns 
 */
export const fetchShareWithCommunityActivityTransactions = (filters = PAGINATION, callback = null) => dispatch => {
    // console.log(`fetchActivityTransactions filters `, filters)
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/activities-instances/share-with-community-activity-transactions?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.search ? `&search=${encodeURIComponent(filters?.search).replace(/%20/, "+")}` : ''}${filters?.status ? `&status=${filters?.status}` : ''}${filters?.validationMethod ? `&validationMethod=${filters?.validationMethod}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}`)
        .then(res => {
            dispatch({
                type: FETCH_SHARE_WITH_COMMUNITY_ACTIVITY_TRANSACTION,
                payload: res.data
            })
            if (callback) callback(res.data)
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in Fetching Transactions", e)
            console.log(e)
        })
}

/**
 * #46334 - Add new PENDING Transactions
 * @param {*} filters 
 * @param {*} callback 
 * @returns 
 */
export const fetchPendingShareWithCommunityActivityTransactions = (filters = PAGINATION, callback = null) => dispatch => {
    // console.log(`fetchActivityTransactions filters `, filters)
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/activities-instances/pending-share-with-community-activity-transactions?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.search ? `&search=${encodeURIComponent(filters?.search).replace(/%20/, "+")}` : ''}${filters?.status ? `&status=${filters?.status}` : ''}${filters?.validationMethod ? `&validationMethod=${filters?.validationMethod}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}`)
        .then(res => {
            dispatch({
                type: FETCH_SHARE_WITH_COMMUNITY_ACTIVITY_TRANSACTION,
                payload: res.data
            })
            if (callback) callback(res.data)
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in Fetching Transactions", e)
            console.log(e)
        })
}

/**
 * 
 * @param {*} newTransactionData 
 * @returns 
 */
export const refreshNewTransaction = (newTransactionData) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    dispatch({
        type: FETCH_TRANSACTION_BY_ID,
        payload: newTransactionData
    })
    dispatch(toggleNetworkLoading(false))
}

/**
 * 
 * @returns 
 */
export const activityTransactionsExportCsvAll = (filters, _selected_rows = []) => dispatch => {
    // dispatch(toggleNetworkLoading(true))
    ServerApiCSV().post(`/activities-instances/activity-transactions-export-csv-all?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.search ? `&search=${encodeURIComponent(filters?.search).replace(/%20/, "+")}` : ''}${filters?.status ? `&status=${filters?.status}` : ''}${filters?.validationMethod ? `&validationMethod=${filters?.validationMethod}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}`, { ...filters, selected_rows: _selected_rows })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `activity-transactions_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(error => {
            message.error("No data found!")
            // dispatch(toggleNetworkLoading(false))
            // console.log(error.response.data)
        })
}

/**
 * 
 * @returns 
 */
export const shareWithCommunityActivityTransactionsExportAll = (filters, _selected_rows = []) => dispatch => {
    // dispatch(toggleNetworkLoading(true))
    ServerApiCSV().post(`/activities-instances/share-with-community-activity-transactions-export-all?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.search ? `&search=${encodeURIComponent(filters?.search).replace(/%20/, "+")}` : ''}${filters?.status ? `&status=${filters?.status}` : ''}${filters?.validationMethod ? `&validationMethod=${filters?.validationMethod}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}`, { ...filters, selected_rows: _selected_rows })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `share-with-community-activity-transactions_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(e => {
            message.error("Error Fetching data!")
            // dispatch(toggleNetworkLoading(false))
            console.error(e)
        })
}

/**
 * 
 * @param {*} params 
 * @param {*} successCallback 
 * @returns 
 */
export const approveOrRejectTransaction = (params, successCallback) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().post(`/activities-instances/activity-transactions-reject-approve-transaction`, params)
        .then(res => {
            if (res.status === 200 || res.status === 201) {
                message.success("Transaction updated.")
                dispatch(toggleNetworkLoading(false))
            } else {
                message.warning("Unable to update transaction.")
                dispatch(toggleNetworkLoading(false))
            }
            successCallback(res)
        })
        .catch(e => {
            message.error(e)
            dispatch(toggleNetworkLoading(false))
        })
}

/**
 * #45721 - Reward Transaction - add option to allow the admin reject the transaction
 * @param {*} _selected_rows 
 * @param {*} callback 
 * @returns 
 */
export const handleRejectThisActivityTransaction = (_selected_rows, callback) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().post(`/activities-instances/reject-this-activity-transaction`, { selected_rows: _selected_rows })
        .then(res => {
            message.success("Transaction rejected!")
            if (callback) callback()
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            console.error(e)
            if (callback) callback()
        })
}