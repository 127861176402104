import { FETCH_QUEST_PENDING_TRANSACTIONS, FETCH_QUEST_TRANSACTIONS, FETCH_QUEST_TRANSACTION_BY_ID } from "./types"
import { toggleNetworkLoading } from "../../common/actions"
import { message } from 'antd'
import ServerApi from "../../../utility/ServerApi"
import { PAGINATION } from "../../../const/utils"
import ServerApiCSV from "../../../utility/ServerApiCSV"
import moment from 'moment'

/**
 * #49026 - Dashboard - Quest Transactions
 * @param {*} filters 
 * @param {*} callback 
 * @returns 
 */
export const fetchQuestTransactions = (filters = PAGINATION, callback = null) => dispatch => {
    // console.log(`fetchActivityTransactions filters `, filters)
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/activities-instances/quest-transactions?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.search ? `&search=${encodeURIComponent(filters?.search).replace(/%20/, "+")}` : ''}${filters?.status ? `&status=${filters?.status}` : ''}${filters?.validationMethod ? `&validationMethod=${filters?.validationMethod}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.questId ? `&questId=${filters?.questId}` : ''}`)
        .then(res => {
            dispatch({
                type: FETCH_QUEST_TRANSACTIONS,
                payload: res.data
            })
            if (callback) callback(res.data)
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in Fetching Transactions", e)
            console.log(e)
        })
}

/**
 * #49026 - Dashboard - Quest Transactions
 * @param {*} filters 
 * @param {*} callback 
 * @returns 
 */
export const fetchPendingQuestTransactions = (filters = PAGINATION, callback = null) => dispatch => {
    // console.log(`fetchActivityTransactions filters `, filters)
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/activities-instances/pending-quest-transactions?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.search ? `&search=${encodeURIComponent(filters?.search).replace(/%20/, "+")}` : ''}${filters?.status ? `&status=${filters?.status}` : ''}${filters?.validationMethod ? `&validationMethod=${filters?.validationMethod}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.questId ? `&questId=${filters?.questId}` : ''}`)
        .then(res => {
            dispatch({
                type: FETCH_QUEST_PENDING_TRANSACTIONS,
                payload: res.data
            })
            if (callback) callback(res.data)
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in Fetching Transactions", e)
            console.log(e)
        })
}

/**
 * #49026 - Dashboard - Quest Transactions
 * @param {*} id 
 * @returns 
 */
export const fetchTransactionById = (id) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/activities-instances/activity-transactions-details/${id}`)
        .then(res => {
            dispatch({
                type: FETCH_QUEST_TRANSACTION_BY_ID,
                payload: res.data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            message.error("Error Fetching data!")
            dispatch(toggleNetworkLoading(false))
            console.log(e)
        })
}

/**
 * 
 * @returns 
 */
export const activityTransactionsExportCsvAll = (filters, _selected_rows = []) => dispatch => {
    // dispatch(toggleNetworkLoading(true))
    ServerApiCSV().post(`/activities-instances/quest-transactions-export-csv-all?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.search ? `&search=${encodeURIComponent(filters?.search).replace(/%20/, "+")}` : ''}${filters?.status ? `&status=${filters?.status}` : ''}${filters?.validationMethod ? `&validationMethod=${filters?.validationMethod}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.questId ? `&questId=${filters?.questId}` : ''}`, { ...filters, selected_rows: _selected_rows })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `quest-transactions_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(e => {
            message.error("No data found!")
            // dispatch(toggleNetworkLoading(false))
            console.error(e)
        })
}