import { LOGIN, LOGOUT, PROMPT_2FA_SETUP, SET_2FA_DATA } from "./types"

const initialState = {
    loggedIn: false,
    userData: {},
    verifyData: null
}

/**
 * 
 * @param {Object} state - The initial state
 * @param {any} action - The data passed from action
 * @returns 
 */
const authReducer = (state = initialState, action) => {
  console.log(action)
    switch (action.type) {
      case LOGIN:
        return {
          ...state,
          loggedIn: true,
          userData: action.payload,
          verifyData: null
        }
      case LOGOUT:
        return { ...state, userData: {}, loggedIn: false, verifyData: null }
      case SET_2FA_DATA:
        return { ...state, verifyData: action.payload }
      default:
        return state
    }
}
  
export default authReducer
  