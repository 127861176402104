import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Modal, Button, message, Checkbox } from "antd"
import Cookies from "js-cookie"
import { InputOTP } from 'antd-input-otp'

import ServerApi from "../../../utility/ServerApi"

const Verify2FA = ({ visible, onClose, onSuccess }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState([])
  const [checked, setChecked] = useState(false)

  const { verifyData } = useSelector((state) => ({
    verifyData: state.auth.verifyData
  }))

  const handleVerify = async () => {
    setLoading(true)
    await ServerApi().post(`auth/2fa/verify`, { email: verifyData, code: value.join(""), isBrowserRemembered: checked })
      .then(res => {
        sessionStorage.setItem("userData", JSON.stringify(res.data?.data))
        if (checked) {
          Cookies.set('remember_2fa', checked, { expires: 30, secure: true })
        }
        dispatch({ type: "SET_2FA_DATA", payload: null })
        onSuccess()
      }).catch(e => {
        console.error(e)
        message.error(e?.response?.data?.message || "Invalid OTP")
      }).finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal title="Two-Factor Authentication" open={visible} footer={null} destroyOnClose width={650}
      onCancel={() => {
        onClose()
        setValue([])
        dispatch({ type: "SET_2FA_DATA", payload: null })
      }}
    >
      <div className="flex flex-col items-center gap-4">
        <InputOTP onChange={setValue} value={value} classNames={{
          input: 'w-10 h-10'
        }} />
        <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)}>Remember this browser for 30days</Checkbox>
        <div className="flex items-center justify-center gap-2">
          <Button onClick={() => {
            setValue([])
          }} type="dashed">
            Clear
          </Button>
          <Button loading={loading} onClick={handleVerify}>
            Verify OTP
          </Button>
        </div>
        <span className="items-center">Lost your 2FA code?</span>
        <span className="items-center justify-center">Please try reset your password via Forgot Password to reset your 2FA.</span>
      </div>
    </Modal>
  )
}

export default Verify2FA
