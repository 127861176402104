/* eslint-disable no-useless-return */
import { LOGIN, LOGOUT, SET_2FA_DATA } from "./types"
import { message } from 'antd'
import Cookies from 'js-cookie'
import { toggleNetworkLoading } from "../common/actions"
import ServerApi from "../../utility/ServerApi"

export const doLogin = (rawData, callback) => async dispatch => {
  dispatch(toggleNetworkLoading(true))
  await ServerApi().post(`auth/admin-login`, {...rawData, rememberMe: Cookies.get('remember_2fa') === 'true'})
    .then(res => {
      const userData = res.data

      if (userData.statusCode === 100 || userData.statusCode === 102) {
        dispatch({
          type: SET_2FA_DATA,
          payload: rawData.email
        })
        if (userData.statusCode === 100) {
          callback.setup2FA()
        } else {
          callback.verify2FA()
        }
        return
      }

      // localStorage.setItem("userData", JSON.stringify(userData.data))
      sessionStorage.setItem("userData", JSON.stringify(userData.data))
      callback.goToDashboard(res.data)
    })
    .catch(e => {
      console.error(e)
      message.error(e?.response?.data?.message || "Invalid Email / Password")
    })
    .finally(() => {
      dispatch(toggleNetworkLoading(false))
    })
}

/**
 * 
 * @param { Object } data - The user data from API 
 */
export const handleLogin = (data) => dispatch => {
  dispatch({
    type: LOGIN,
    payload: data
  })
}

export const handleLogout = () => {
  // localStorage.setItem("userData", null)
  sessionStorage.setItem("userData", null)
  return dispatch => {
    dispatch({ type: LOGOUT })
  }
}

export const changePassword = (rawData, successCallback, errorCallback) => dispatch => {
  ServerApi().post(`auth/change-password`, rawData)
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        message.success("Password was changed, Please login again!", 2)
        sessionStorage.removeItem('userData')
        successCallback()
      } else {
        message.error(res?.data?.message || "Change password error", 2)
        if (errorCallback) {
          errorCallback()
        }
      }
    })
    .catch(e => {
      message.error(e?.response?.data?.message || "Change password error", 2)
      // console.log(e)
      if (errorCallback) {
        errorCallback()
      }
    })
}

export const forgotPassward = (rawData, successCallback, errorCallback) => dispatch => {
  ServerApi().post(`auth/user/forgot-password-reset`, rawData)
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        // message.success("Password Reset Successfull")
        successCallback()
      } else {
        // message.error("Error Changing Password")
        if (errorCallback) {
          errorCallback()
        }
      }
      // successCallback()
    })
    .catch(e => {
      // message.error(e.response.data.message || "Error resetting password!", e)
      // console.log(e)
      if (errorCallback) {
        errorCallback()
      }
    })
}

export const forgotPassword = (rawData, successCallback, errorCallback) => async dispatch => {
  dispatch(toggleNetworkLoading(true))
  await ServerApi().post(`auth/user/forgot-password`, rawData)
  .then(res => {
    if (res.status === 200 || res.status === 201) {
      message.success("Reset password was sent to your email!")
      if (successCallback) {
        successCallback()
      }
    } else {
      message.error(res?.data?.message || "Reset password error")
      if (errorCallback) {
        errorCallback()
      }
    }
  })
  .catch(e => {
    message.error(e.response.data.message || "Error resetting password!", 2)
    if (errorCallback) {
      errorCallback()
    }
  })
  .finally(() => {
    dispatch(toggleNetworkLoading(false))
  })
}


export const validateResetPasswordToken = (rawData, successCallback, errorCallback) => dispatch => {
  ServerApi().post(`auth/user/forgot-password-validate-token`, rawData)
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        // message.success("OK")
        successCallback()
      } else {
        // message.error("Error")
        if (errorCallback) {
          errorCallback()
        }
      }
      // successCallback()
    })
    .catch(e => {
      // message.error(e.response.data.message || "Error resetting password!", e)
      // console.error(e)
      if (errorCallback) {
        errorCallback()
      }
    })
}